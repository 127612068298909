//region References
import React, { Component } from 'react';
import '../../customStyle.css';
import DomainURL, { ApiEndpoints, Get, Post } from '../../ApiEndpoints';
import { GetAddressByIP } from '../GeoLocation';
import { CSRating, RatingFill, EmptyRatings } from './util.style';
import { Col, Row } from 'antd';
import Pagination from '@isomorphic/shared/isomorphic/components/uielements/pagination';
import ContentHolder from '@isomorphic/shared/isomorphic/components/utility/contentHolder';
import AutoComplete, {
  AutoCompleteOption,
} from '@isomorphic/shared/isomorphic/components/uielements/autocomplete';
import Button from '@isomorphic/shared/isomorphic/components/uielements/button';
import Modal from '@isomorphic/shared/isomorphic/components/Feedback/Modal';
import { InputGroup } from '@isomorphic/shared/isomorphic/components/uielements/input';
import Select, {
  SelectOption,
} from '@isomorphic/shared/isomorphic/components/uielements/select';
import notifications from '@isomorphic/shared/isomorphic/components/Feedback/Notification';
import Map, {
  MapDataProcessing,
} from '@isomorphic/shared/hotel/components/Map/Map';
import { GetCurrentArea } from '../GeoLocation';
//endregion

const Option = AutoCompleteOption;

//iFram Component
export const IFrame = () => {
  return <iframe src="https://www.trawell.in" title="myFrame"></iframe>;
};

//Notification Component
export const openNotification = props => {
  notifications[props.type]({
    message: props.Heading,
  });
};

//BreadCrumbs Component
const BreadCrumbs = props => {
  const _getAnchor = Obj => {
    if (Obj.isActive) {
      return (
        <a
          title={Obj.Text.replace(
            '<CurentCity>',
            props.city !== undefined ? props.city : ''
          ).replace(
            ',<CurrentArea>',
            props.area !== '' && props.area !== undefined
              ? `/${props.area}`
              : ''
          )}
          href={Obj.path
            .replace(
              '<CurentCity>',
              `/${props.city !== undefined ? props.city : ''}`
            )
            .replace(
              '<CurrentArea>',
              `${props.area !== '' ? `/${props.area}` : ''}`
            )}
        >
          {Obj.Text.replace(
            '<CurentCity>',
            props.city !== undefined ? props.city : ''
          ).replace(
            ',<CurrentArea>',
            props.area !== '' ? `, ${props.area}` : ''
          )}
        </a>
      );
    } else {
      return (
        <>
          {Obj.Text.replace(
            '<CurentCity>',
            props.city !== undefined ? props.city : ''
          ).replace(
            ',<CurrentArea>',
            props.area !== '' ? `, ${props.area}` : ''
          )}
        </>
      );
    }
  };

  return (
    <ul className="breadcrumb gainsboro">
      {props.options.map((obj, index) => {
        return <li key={index}>{_getAnchor(obj)}</li>;
      })}
    </ul>
  );
};

//GroupInput Component
export class GroupInput extends Component {
  render() {
    return (
      <InputGroup
        compact
        style={{ marginBottom: '15px' }}
        className={this.props.className}
      >
        <AutoSearh
          className="br0 b0 rIocn inp-border inp-icon-clr autoSrch_input width-150"
          type="destination"
          placeholder={'Select City'}
          onSelectDestination={val => {}}
        />
        <AutoSearh
          className="br0 b0 rIocn inp-border inp-icon-clr autoSrch_input"
          type="destination"
          placeholder={'Search a package'}
          onSelectDestination={val => {}}
        />
      </InputGroup>
    );
  }
}

//AutoComplete Component
export class AutoSearh extends Component {
  constructor(props) {
    super(props);
    this.state = {
      result: [],
      type: this.props.type,
      ispackage: this.props.is_package,
      Areacity: this.props.Areacity,
    };

    this._getURI = this._getURI.bind(this);
    this.handleCustomizedChange = this.handleCustomizedChange.bind(this);
    this.handleUserSearch = this.handleUserSearch.bind(this);
    this.handleOnSelect = this.handleOnSelect.bind(this);
  }

  handleCustomizedChange = async (value, event) => {
    var $that = this;
    if (this.props.type !== 'globalSearch') {
      this.setState({ result: [] }, async () => {
        this.props.onSelectDestination(value);
        var URL = await this._getURI(value);

        let data = await Get(URL);

        if (!data.error) {
          if (data.length > 0) {
            if ($that.state.type === 'ServiceCities') {
              this.setState({ result: data });
            } else if ($that.state.type === 'destination') {
              if (data.length === 0) {
                data.push('coorg');
              }
              this.setState({ result: data });
            } else if ($that.state.type === 'ServiceArea') {
              if (data.length > 0) {
                this.setState({ result: data });
              } else {
                this.setState({ result: [] });
              }
            }
          } else {
            this.setState({ result: [] });
            this.handleUserSearch(value);
          }
        }
      });
    } else {
      this.props.onSelectDestination(value, [], false);
      this.setState({ result: [] }, async () => {
        if (value.length > 2) {
          var URL = await this._getURI(value);

          let data = await Get(URL);

          if (!data.error) {
            if (data.length > 0) {
              this.props.onSelectDestination(value, data, true, event);
              if (
                this.props.is_package.location.pathname.indexOf(
                  'tour-packages'
                ) === 1
              ) {
                this.setState({
                  result: data.sort(function(x, y) {
                    // true values first
                    return x['is_package'] === y['is_package']
                      ? 0
                      : x['is_package']
                      ? -1
                      : 1;
                  }),
                });
              } else {
                this.setState({
                  result: data.sort(function(x, y) {
                    //false values first
                    return x['is_package'] === y['is_package']
                      ? 0
                      : x['is_package']
                      ? 1
                      : -1;
                  }),
                });
              }
            } else {
              this.props.onSelectDestination(value, [], true, event);
              this.handleUserSearch(value);
              this.setState({ result: [] });
            }
          }
        }
      });
    }
  };

  handleUserSearch = async value => {
    return await Post(`${DomainURL}${ApiEndpoints.UserSearch}`, {
      searchString: value,
      SearchPage: window.location.pathname,
      SearchIn: this.state.type,
      datetime: new Date(),
    });
  };

  handleOnSelect = async value => {
    if (this.props.type === 'globalSearch') {
      var URL = await this._getURI(value);
      let data = await Get(URL);

      if (!data.error) {
        if (data.length > 0) {
          this.props.onSelect(value, data[0]);
        }
      }
    }
  };

  _getURI = value => {
    switch (this.state.type) {
      case 'destination':
        return `${DomainURL}${ApiEndpoints.Destinations}?SearchString=${value}`;
      case 'Fromdestination':
        return `${DomainURL}${ApiEndpoints.Destinations}?SearchString=${value}`;
      case 'globalSearch':
        return `${DomainURL}${ApiEndpoints.TopSeach}${value}`;
      case 'ServiceCities':
        return `${DomainURL}${ApiEndpoints.ServiceCity}${value}`;
      case 'ServiceArea':
        return `${DomainURL}${ApiEndpoints.ServiceArea}?area=${value}&city=${this.props.Areacity}&package=${this.props.package}`;
      default:
        return `${DomainURL}${ApiEndpoints.Destinations}?SearchString=${value}&type=${this.state.type}`;
    }
  };

  _getOption = item => {
    switch (this.state.type) {
      case 'city':
        return <Option key={item}>{item}</Option>;
      case 'address':
        return <Option key={item}>{item}</Option>;
      case 'destination':
        return (
          <Option key={item} dataVal={item}>
            {item}
          </Option>
        );
      case 'globalSearch':
        return <Option key={item.search_string}>{item.search_string}</Option>;
      case 'ServiceCities':
        return <Option key={item}>{item}</Option>;
      case 'ServiceArea':
        return <Option key={item}>{item}</Option>;
      default:
        break;
    }
  };

  render() {
    const { result } = this.state;
    const children = result.map(item => {
      return this._getOption(item);
    });
    return (
      <AutoComplete
        className={this.props.className}
        onSelect={this.handleOnSelect}
        onChange={(value, event) => {
          this.handleCustomizedChange(value, event);
        }}
        onFocus={(value, event) => {
          this.handleCustomizedChange(this.props.value, event);
        }}
        placeholder={this.props.placeholder}
        value={this.props.value}
        autoComplete="new-dest-city"
      >
        {children}
      </AutoComplete>
    );
  }
}

//Validate Email Function
export const validateEmail = email => {
  var re = /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

//Rating Conversion Function
export const _getRating = value => {
  return value !== 4.5 ? Math.round(value) : value;
};

//NoData Component
export const Nodata = () => (
  <Row className="d-flex-center d-clm fullHeight">
    <Col md={24} className="text-cnt noData_img">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="64"
        height="41"
        viewBox="0 0 64 41"
      >
        <g transform="translate(0 1)" fill="none" fillRule="evenodd">
          <ellipse fill="#E9E9E9" cx="32" cy="33" rx="32" ry="7" />
          <g fillRule="nonzero" stroke="#D9D9D9">
            <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z" />
            <path
              d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
              fill="#FAFAFA"
            />
          </g>
        </g>
      </svg>
    </Col>
    <Col md={24} className="text-cnt noData_txt">
      No Data Found
    </Col>
  </Row>
);

//Model Component
export const GlobalModal = props => (
  <Modal
    className={
      props.className !== undefined ? props.className : 'globalQouteModel'
    }
    id={props.id}
    visible={props.visible}
    title={props.title}
    onOk={props.onOk}
    onCancel={props.onCancel}
    footer={
      props.isFooter
        ? [
            <Button key="back" size="medium" onClick={props.onCancel}>
              {props.CancelText}
            </Button>,
            <Button
              key="submit"
              type="primary"
              size="medium"
              loading={props.loading}
              onClick={props.onOk}
            >
              {props.SubmitText}
            </Button>,
          ]
        : null
    }
  >
    {props.Content}
  </Modal>
);

//Custom Rating Component
export class RatePer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stars: Array(this.props.stars)
        .fill()
        .map(() => Math.round(Math.random() * 40)),
      conWidth: 0,
    };
  }

  componentDidMount = () => {
    var conWidth = document.getElementById('starCont').offsetWidth;
    this.setState({
      conWidth: conWidth,
    });
  };

  render() {
    return (
      <CSRating style={{ width: this.state.conWidth }}>
        <RatingFill
          style={{ width: `${(this.props.rating * 100) / this.props.stars}%` }}
        >
          <span id="starCont">
            {this.state.stars.map((o, i) => {
              return <span key={i}>★</span>;
            })}
          </span>
        </RatingFill>
        <EmptyRatings>
          <span id="starCont">
            {this.state.stars.map((o, i) => {
              return <span key={i}>★</span>;
            })}
          </span>
        </EmptyRatings>
      </CSRating>
    );
  }
}

//Pagging Component
export const Pagging = props => {
  return props.total > 0 ? (
    <Row justify="start">
      <Col md={24}>
        <ContentHolder>
          <Pagination
            className="cstm-pagging"
            showSizeChanger
            onShowSizeChange={(current, pageSize) => {
              props.onShowSizeChange(current, pageSize);
            }}
            onChange={val => {
              props.onChange(val);
            }}
            defaultCurrent={1}
            total={props.total}
          />
        </ContentHolder>
      </Col>
    </Row>
  ) : null;
};

//Convert to Base64 Function
export const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

//Capitilize String Function
export const Capitilize = text => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

//TitleCase String Function
export const titleCase = str => {
  var splitStr = str.toLowerCase().split(' ');
  for (var i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(' ');
};

//Set Element Default Value
export const setNativeValue = (element, value) => {
  let lastValue = element.value;
  element.value = value;
  let event = new Event('input', { target: element, bubbles: true });
  // React 15
  event.simulated = true;
  // React 16
  let tracker = element._valueTracker;
  if (tracker) {
    tracker.setValue(lastValue);
  }
  element.dispatchEvent(event);
};

//Trim Obj Value
export const TrimObjVal = value => {
  var getArr = Object.keys(value).map(k =>
    value[k] !== undefined ? (value[k] = value[k].trim()) : ''
  );
  var isvalid = false;
  getArr.map((e, i) => {
    if (e === '') {
      isvalid = true;
    }
    return false;
  });

  if (isvalid) {
    var Properties = Object.keys(value);
    Properties.map((p, index) => {
      return (value[p] =
        value[p] !== '' && value[p] !== undefined ? value[p] : '');
    });
    isvalid = true;
  }
  return {
    isvalid: isvalid,
    value: value,
  };
};

//Open New Browser Tab
export const OpenNewtab = async url => {
  var win = await window.open(url, '_blank');
  if (win != null) {
    win.focus();
  }
};

//Remove Css Class from Element
export const removeClass = (elementID, className) => {
  var element = document.getElementById(elementID);
  if (element != null) {
    element.classList.remove(className);
  }
};

//Add Css Class in Element
export const addClass = (elementID, className) => {
  var element = document.getElementById(elementID);
  if (element != null) {
    element.classList.add(className);
  }
};

//Toggle Css Class in Element
export const toggleClass = (elementID, className) => {
  var element = document.getElementById(elementID);
  element.classList.toggle(className);
};

//Make Element Sticky
export const StickyElements = (elementID, className, topPos) => {
  if (
    document.body.scrollTop > topPos ||
    document.documentElement.scrollTop > topPos
  ) {
    addClass(elementID, className);
  } else {
    removeClass(elementID, className);
  }
};

//Map Location Component
export const mapLocation = props => (
  <Map>
    <MapDataProcessing
      cstmMap={true}
      location={props.location}
      multiple={false}
    />
  </Map>
);

//MultiSelect Component
export const MultiSelect = props => (
  <Select
    className="multiSelect"
    mode="multiple"
    defaultValue={props.defaultValue}
    style={{ width: '100%' }}
    allowClear
    onChange={value => props.handleService(value)}
    // placeholder="Please Select"
  >
    {props.options.map(option => {
      return (
        <SelectOption className="multiSelectInput" value={option.Text}>
          {option.Text}{' '}
        </SelectOption>
      );
    })}

    {/* <SelectOption value="lucy">Lucy</SelectOption>
  <SelectOption value="lucy1">Lucy1</SelectOption>
  <SelectOption value="lucy2">Lucy2</SelectOption> */}
  </Select>
);

//SortBy Global Func
export const sortBy = (arr, p, desc) => {
  if (desc === 'desc') {
    return arr.sort(function(a, b) {
      return (a[p] === '') - (b[p] === '') || -(a[p] > b[p]) || +(a[p] < b[p]);
    });
  } else {
    return arr.sort(function(a, b) {
      return (a[p] === '') - (b[p] === '') || +(a[p] > b[p]) || -(a[p] < b[p]);
    });
  }
};

//noZero Sort
export const noZeroSort = (arr, p, desc) => {
  return arr.sort((a, b) => {
    if (a[p] === 0) return 1; //Return 1 so that b goes first
    if (b[p] === 0) return -1; //Return -1 so that a goes first
    return a[p] - b[p];
  });
};

//Get Address By IP Address this is for City Dropdown default value in Service Type Search Form
export const SetCurrentCity = callback => {
  GetAddressByIP(async loc => {
	 console.log ("loc", loc);
    if (loc["country_name"] && loc.country_name.toLowerCase() === 'india') {
      GetCurrentArea(loc.latitude, loc.longitude, async res => {
        var addObj = res.split(',');
        //var CurrntCity = addObj[addObj.length - 3].trim();
        var CurrntCity = loc.city;
        var CurrntArea = ''; //addObj[addObj.length - 4].trim();
		
		try{
			let data = await Get(
			  `${DomainURL}${ApiEndpoints.Destinations}?SearchString=${CurrntCity}`
			);
			if (!data.error) {
			  callback(
				data.length > 0
				  ? {
					  city: CurrntCity,
					  area: CurrntArea,
					}
				  : {
					  city: CurrntCity,
					  area: CurrntArea,
					}
			  );
			} else {
			  callback({
				city: CurrntCity,
				area: CurrntArea,
			  });
			}
		} catch (e) {
		  callback({
			city: 'Bangalore',
			area: '',
		  });
		}
		
      });
    } else {
      callback({
        city: 'Bangalore',
        area: '',
      });
    }
  });
};

//SortBy Any Prop in Obj
export const sortByDate = (arr, prop, des) => {
  return arr.sort(function(a, b) {
    // Turn your strings into dates, and then subtract them
    // to get a value that is either negative, positive, or zero.
    return new Date(b[prop]) - new Date(a[prop]);
  });
};

//AutoFill BestQuote with LoggedIn User Info
export const bestQoute_autoFill = (className, value) => {
  var emailElements = document.getElementsByClassName(className);
  for (let index = 0; index < emailElements.length; index++) {
    setNativeValue(emailElements[index], value);
  }
};

//Get Distance between two endPoints
export const LatLngDist = async (lat1, lng1, lat2, lng2, callback) => {
  // Convert Degress to Radians
  function Deg2Rad(deg) {
    return (deg * Math.PI) / 180;
  }

  function PythagorasEquirectangular(lat1, lon1, lat2, lon2) {
    lat1 = Deg2Rad(lat1);
    lat2 = Deg2Rad(lat2);
    lon1 = Deg2Rad(lon1);
    lon2 = Deg2Rad(lon2);
    // var R = 6371; // km
    var R = 3959; // miles
    var x = (lon2 - lon1) * Math.cos((lat1 + lat2) / 2);
    var y = lat2 - lat1;
    var d = Math.sqrt(x * x + y * y) * R;
    return d;
  }

  callback(await PythagorasEquirectangular(lat1, lng1, lat2, lng2));
};

//Get Difference of two Arrays
export const Arraydifference = async (arrayOne, arrayTwo) => {
  return await arrayOne.filter(
    OBJ1 =>
      !arrayTwo.some(OBJ2 => JSON.stringify(OBJ2) === JSON.stringify(OBJ1))
  );
};

//Calculate Nearest Distance
export const _calculateNearest = async (data, lat, lng, km) => {
  var arrayPck = [];

  await data.map(async (obj, index) => {
    await LatLngDist(lat, lng, obj.Latitude, obj.Longitude, dstance => {
      return dstance <= parseFloat(km) ? arrayPck.push(obj) : null;
    });
  });

  return arrayPck;
};

export default BreadCrumbs;

import { Component } from 'react';
import Geocode from 'react-geocode';
Geocode.setApiKey('AIzaSyBUH2yhTSBPhrU3UdDX_POt3W4X04N4lSU');

const defulatLoc = {
    "ip": "49.207.214.80",
    "network": "49.207.208.0/20",
    "version": "IPv4",
    "city": "Bangalore",
    "region": "Karnataka",
    "region_code": "KA",
    "country": "IN",
    "country_name": "India",
    "country_code": "IN",
    "country_code_iso3": "IND",
    "country_capital": "New Delhi",
    "country_tld": ".in",
    "continent_code": "AS",
    "in_eu": false,
    "postal": "562130",
    "latitude": 12.9753,
    "longitude": 77.591,
    "timezone": "Asia/Kolkata",
    "utc_offset": "+0530",
    "country_calling_code": "+91",
    "currency": "INR",
    "currency_name": "Rupee",
    "languages": "en-IN,hi,bn,te,mr,ta,ur,gu,kn,ml,or,pa,as,bh,sat,ks,ne,sd,kok,doi,mni,sit,sa,fr,lus,inc",
    "country_area": 3287590.0,
    "country_population": 1352617328,
    "asn": "AS24309",
    "org": "Atria Convergence Technologies Pvt. Ltd. Broadband Internet Service Provider INDIA"
}

class GeoLocation extends Component {
  _displayLocationInfo = position => {};
  _getLocation() {
    navigator.geolocation.getCurrentPosition(this._displayLocationInfo);
    return null;
  }
  render() {
    return this._getLocation();
  }
}

export const _displayLocationInfo = (position, callback) => {
  Geocode.fromLatLng(position.lat, position.lng).then(
    response => {
      const address = response.results[0].formatted_address;
      callback(address);
    },
    error => {
      console.error(error);
    }
  );
};

export const _getLocation = () => {
  navigator.geolocation.getCurrentPosition(_displayLocationInfo);
  return null;
};

export const getAddress = async callback => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      async props => {
        await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${props.coords.latitude},${props.coords.longitude}&key=AIzaSyBUH2yhTSBPhrU3UdDX_POt3W4X04N4lSU`
        )
          .then(res => res.json())
          .then(resJ => {
            callback(resJ.results[0].formatted_address);
          });
      },
      failure => {
        callback({
          Heading: 'Only secure origins are allowed',
          type: 'error',
        });
      }
    );
  } else {
    callback({
      Heading: 'Sorry, your browser does not support geolocation services.',
      type: 'error',
    });
  }
};

export const GetCurrentArea = async (latitude, longitude, callback) => {
  await Geocode.fromLatLng(latitude, longitude).then(
    async response => {
      await callback(response.results[0].formatted_address);
    },
    error => {
      console.error(error);
    }
  );
};
export const GetLocationByAddress = async (address, callback) => {
  await Geocode.fromAddress(address).then(
    async response => {
      const { lat, lng } = response.results[0].geometry.location;
      console.log(lat, lng);
      await callback(lat, lng);
    },
    error => {
      console.error(error);
    }
  );
};

export const GetAddressByIP = async callback => {
  //https://freegeoip.app/json/
  //https://geoip-db.com/jsonp
  //fetch(`https://freegeoip.app/json/`)

  try {
    (`https://ipapi.co/json/`)
    .then(res => res.json())
    .then(resJ => {
      callback(resJ);
    });
  } catch (e) {
	  callback(defulatLoc);
  }
};

export default GeoLocation;
